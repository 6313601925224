import Component from "../models/Component";
import $ from 'jquery';
import Player from "@vimeo/player";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default class VideovimeoHp extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
    });
  }

  init() {
    this.playerContainer = this.element.find('[data-embed-target]');
    this.autoplay = this.getData('autoplay');
    this.type = this.getData('type');
    this.playerContainer.find('img').hide();
    this.playButton = this.playerContainer.find('.play-button');
    this.overlay = this.element.find('.overlay-video');
    this.pause = this.element.find('.pause ');
    this.fullscreenControl = 1;
    this.videoHp = this.element.find('.video-hp').get(0);
    this.wrapper = $('#app');

    this.player = new Player(this.playerContainer.get(0), {
      id: this.getData('vimeo-video-id'),
      autoplay: true,
      fullscreen: true,
      responsive: true,
      loop: true,
      dnt: 1,
      background: this.autoplay ? true : false,
    });

    this.playButton.on('click', () => {
      this.animationVideo();
      this.player.play();
      this.player.setVolume(1);
      this.player.setCurrentTime(0);
    });

    this.overlay.on('click', () => {
      if (this.overlay.hasClass('stop')) {
        this.player.pause();
        this.player.setVolume(0);
        var self = this.overlay;
        setTimeout(function () {
          $('.play-button').hide();
          $('.play-button-after-stop').show();
          $(self).removeClass('stop');
        }, 100)
      } else {

        if (this.overlay.hasClass('musica')) {
          this.playVideo();
          this.player.setVolume(1);
          $('.play-button-after-stop').hide();
          var self = this.overlay;
          setTimeout(function () {
            $(self).addClass('stop');
          }, 100)
        }

      }

    });

    this.player.on('ended', () => {
      if (this.fullscreenControl == 2) { 
        this.animationVideo();
      }
      this.player.play();
      this.player.setVolume(0);
      this.player.setCurrentTime(0);
    });
    

    this.pause.on('click', () => {
      this.animationVideo();
      this.player.setVolume(0);
      this.playVideo();
      $('.play-button-after-stop').hide();
    });

    this.player.on('pause', () => {
      this.overlay.show();
      this.playButton.fadeIn();
      this.player.setVolume(0);
    });
  }

  playVideo() {
    this.player.play();
  }

  animationVideo() {

    if (this.fullscreenControl == 1) {
      this.pause.show();
      this.playButton.hide();
      $('.overlay-video').addClass('musica stop');
      $(this.videoHp).addClass('fullscreen');
      $('html, body').addClass('no-scroll');
      this.element.css({
        'background': '#4b5c66cc',
        'backdrop-filter': 'blur(5px)',
        'bottom': '0'
      });      
      this.wrapper.css('z-index', 200);

      gsap.to(
        this.videoHp, {
          bottom: '50%',
          y: '50%',
          right: '0px',
          width: '100%',
          duration: 0.5,
          scrollTrigger: {
            trigger: this.element,
            start: 'center bottom',
            end: 'bottom bottom',
          }
        }
      );

      this.fullscreenControl++;

    } else {

      
      this.playButton.show();
      this.pause.hide();
      $('.overlay-video').removeClass('musica stop');
      $('html, body').removeClass('no-scroll');
      $(this.videoHp).removeClass('fullscreen');
      this.element.css({
        'background': 'transparent',
        'backdrop-filter': 'blur(0)',
        'bottom': '25px'
      });
      this.wrapper.css('z-index', 1);


      if (window.innerWidth >= 1024) {

        gsap.to(
          this.videoHp, {
            bottom: '25px',
            y: '0%',
            right: "25px",
            width: '33.3%',
            duration: 0.5,
            scrollTrigger: {
              trigger: this.element,
              start: 'center bottom',
              end: 'bottom bottom',
            }
          }
        );
      } else {
        gsap.to(
          this.videoHp, {
            bottom: '0%',
            y: '0%',
            width: '100%',
            duration: 0.5,
            scrollTrigger: {
              trigger: this.element,
              start: 'center bottom',
              end: 'bottom bottom',
            }
          }
        );
      }

      this.fullscreenControl--;

    }

  }
}