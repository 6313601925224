import $ from 'jquery';
import Component from '../models/Component';

// State names used by the riv file
// 00-intro
// 1-data-analysis-open
// 1-data-analysis-close
// 2-brand-strategy-open
// 2-brand-strategy-close
// 3-service-product-design-open
// 3-service-product-design-close
// 4-change-management-open
// 4-change-management-close
// 5-comunicazione-open
// 5-comunicazione-close

export default class Servizi extends Component {
  constructor(element, context) {
    super(element, context);

    $(() => {
      this.init();
    });
  }

  init() {
    this.previousStateName = null;
    this.direction = 'down';
    this.src = window.location.origin + '/wp-content/uploads/2023/11/conic-def-animazione-nov.riv';
      
      const self = this; // Salva il contesto corrente di this in una variabile

      setTimeout(function(){
        self.r = new rive.Rive({
          src: self.src,
          canvas: self.element.get(0),
          stateMachines: '00-intro',
          autoplay: true,
          onLoad: () => {
            self.element.removeClass('no-visible');
            self.r.resizeDrawingSurfaceToCanvas();
          },
        });
      }, 250);


    // Configurazione dell'Intersection Observer
    const options = {
      root: null, // Utilizza la viewport come root
      rootMargin: '25px',
      threshold: 0.4, // Soglia iniziale
    };

    // Gestione dello scroll
    let prevScrollPos = window.scrollY;
    window.addEventListener("scroll", () => {
      const currentScrollPos = window.scrollY;
      this.direction = currentScrollPos > prevScrollPos ? 'down' : 'up';
      prevScrollPos = currentScrollPos;
    });

    // Crea l'Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      this.handleIntersection(entries)
    }, options);
    const divs = document.querySelectorAll(".category-wrapper-servizi");

    // Registra gli elementi da osservare
    divs.forEach((div) => {
      observer.observe(div);
    });
  }

  handleIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && this.direction === 'down') {
        this.transitionTo(`${entry.target.id}-open`);
      }

      if (!entry.isIntersecting && this.direction === 'up') {
        this.transitionTo(`${entry.target.id}-close`);
      }
    })
  }

  transitionTo(stateName) {
    if (stateName === '00-intro-close') {
      // State 00-intro-close does not exist
      return;
    }

    if (stateName === this.previousStateName) {
      return;
    }

    this.previousStateName = stateName;


    this.r = new rive.Rive({
      src: this.src,
      canvas: this.element.get(0),
      stateMachines: stateName,
      autoplay: true,
      onLoad: () => {
        this.r.resizeDrawingSurfaceToCanvas();
      },
    });
  }
}
