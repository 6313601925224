import {
 gsapSingleton,
 scrollTriggerSingleton
} from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();


let sections = gsap.utils.toArray(".collab");
const serviziScroll = document.querySelector("#managementScroll");


if (serviziScroll) {
 if (window.innerWidth > 1024) {
  gsap.to(sections, {
   xPercent: -100 * (sections.length - 3),
   ease: "none",
   scrollTrigger: {
    start: "top top",
    // end: "bottom bottom",
    trigger: "#managementScroll",
    invalidateOnRefresh: true,
    pin: true,
    scrub: 1,
    snap: 1 / (sections.length - 3),
    end: () => "+=" + document.querySelector("#managementScroll").offsetWidth,
   }
  });
 }
}

setTimeout(function () {
 ScrollTrigger.refresh();
}, 150)