let heroDark = document.getElementsByClassName('hero-dark');

if(heroDark.length && typeof VANTA !== 'undefined') {
 VANTA.FOG({
  el: ".hero-dark",
  mouseControls: true,
  touchControls: true,
  gyroControls: false,
  minHeight: 200.00,
  minWidth: 200.00,
  highlightColor: 0x7ec49f, 
  midtoneColor:  0x4B5C66,  
  lowlightColor: 0x4B5C66,  
  baseColor:  0x4B5C66,
  blurFactor: 1,
  speed: 3.00,
  zoom: 0.50
 });
 
}