import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
  const isSliderCustom = document.querySelector(".slider-custom");
  
  if (isSliderCustom) {
    const slides = document.querySelectorAll(".slide");
    const wrpRight = document.querySelectorAll(".wrp-right");
    const imageWrapper = document.querySelectorAll(".wrp-right .image-animation_wrapper");
    let imageWrapperActive = document.querySelector(".wrp-right .image-animation_wrapper.active");


    // Prima immagine animazione
    let img = imageWrapperActive.querySelector(".image-animation");

    gsap.fromTo(
      imageWrapperActive, 
      {
        scale: 0,
        opacity: 0,
      }, 
      {
        scale: 1,
        opacity: 1,
        duration: 2,
        ease: 'power4',
        scrollTrigger: {
          trigger: wrpRight,
          start: 'top 70%',
        }
      }
    );

    gsap.fromTo(
      img, 
      {
        opacity: 1,
        scale: 5,
      }, 
      {
        scale: 1,
        duration: 2,
        ease: 'power4',
        scrollTrigger: {
          trigger: wrpRight,
          start: 'top 70%',
        }
      }
    );
    
    
    let currentSlide = 1;
    let timer;

    // Funzione per attivare una slide specifica
    function activateSlide(slideNumber) {
      let slideActive = document.querySelector('.slide.is-active');
      let line = slideActive.querySelector('.line');
      let lineAfter = slideActive.querySelector('.line-after');

      gsap.to(line, { height: '100%', duration: 0.5 });
      gsap.to(lineAfter, { height: '100%', duration: 0.5 });

      imageWrapperActive = document.querySelector(".wrp-right .image-animation_wrapper.active");
      img = imageWrapperActive.querySelector(".image-animation");

      let animazioneTimeline = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: wrpRight,
          start: 'top 70%',
        },
        // onComplete: () => {
        //   animazioneTimeline.reverse().progress(1).pause();
        // },
      });
      animazioneTimeline.fromTo(
        imageWrapperActive,
        { opacity: 1 },
        { opacity: 0, duration: 1, ease: 'linear' }
      );

      setTimeout(() => {
        slides.forEach((slide, index) => {
          if (index + 1 === slideNumber) {
            slide.classList.add("is-active");
          } else {
            slide.classList.remove("is-active");
          }
        });
    
        // Nascondi tutte le immagini
        imageWrapper.forEach(imgWrapper => {
          imgWrapper.classList.remove("active");
        });
    
        // Mostra solo l'immagine associata alla slide corrente
        const currentImage = document.querySelector(`.wrp-right .slide-${slideNumber}`);
        currentImage.classList.add("active");
    
        gsap.to(
          imageWrapperActive,
          {
            opacity: 0,
            duration: 0.5,
          }
        )

        gsap.to(
          line,
          {
            height: 0,
          }
        )
        gsap.to(
          lineAfter,
          {
            height: 0,
          }
        )
    
        // Animazione immagini
        imageWrapperActive = document.querySelector(".wrp-right .image-animation_wrapper.active");
        img = imageWrapperActive.querySelector(".image-animation");

        animazioneTimeline = gsap.timeline({
          paused: true,
          scrollTrigger: {
            trigger: wrpRight,
            start: 'top 70%',
          }
        });
      
        animazioneTimeline.fromTo(
          imageWrapperActive, 
          { scale: 0, opacity: 0 }, 
          { scale: 1, opacity: 1, duration: 2, ease: 'power4' }
        );
      
        animazioneTimeline.play();
    
        currentSlide = slideNumber;
      }, 501);
    }



    // Funzione per passare alla prossima slide
    function nextSlide() {
      currentSlide = (currentSlide % slides.length) + 1;
      activateSlide(currentSlide);
    }

    // Gestione del timer per cambiare slide ogni 6 secondi
    function startTimer() {
      const primaSlides = document.querySelectorAll(".slide")[0];
      console.log(primaSlides);
      primaSlides.classList.add("is-active")
      timer = setInterval(nextSlide, 6000);
    }

    function stopTimer() {
      clearInterval(timer);
    }

    // Aggiungi gestori di eventi per le slide stesse
    slides.forEach(slide => {
      slide.addEventListener("click", function () {
        const slideNumber = parseInt(slide.classList[1].replace("slide-", ""));
        activateSlide(slideNumber);
        stopTimer();

        setTimeout(() => {
          document.querySelectorAll('.active-by-user').forEach(function (element) {
            element.classList.remove('active-by-user');
          });
        }, 500);


        // Aggiungi la classe "active-by-user" alla slide corrente dopo un ritardo di 50 millisecondi
        setTimeout(function () {
          slide.classList.add('active-by-user');
        }, 550);

      });
    });

    ScrollTrigger.create({
      trigger: isSliderCustom,
      start: 'top 70%',
      onEnter: () => {
        startTimer()
      },
      once: true,
    }); 
  }
});