import $ from 'jquery';
import Component from "../models/Component";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class ChangeBg extends Component {
    constructor(element, context) {
        super(element, context);

        /* animazione linee */
        const lineWrapper =  this.element.find('.linea-frase_wrapper');
        const lines = this.element.find('.linea-frase');

        /* controlli video */
        const pauseButton = this.element.find('.pause');
        // const playButton = this.element.find('.play');
        const videoHp = this.element.find('.video-hp').get(0);


        pauseButton.css('display', 'none')

        // fadein video
        gsap.fromTo(videoHp,
            {
                opacity: 0,
                y: 30
            },
            {
                opacity: 1,
                y: 0,
                duration: 0.5,
                delay: 1,
                ease: 'ease-in-out',
                scrollTrigger: {
                    trigger: element,
                    start: 'center bottom',
                    end: 'bottom bottom',
                }
            }
        )


        $(() => {

            /* animazione linee */
            $(lineWrapper).each(function(){
                let maxLineWidth = Math.floor(Math.random() * (101)) + 50 + "%";
                $(this).css('width', maxLineWidth)
            })

            let i = 0
            $(lines).each(function() {

                let line = $(this).get(0);
                let calcDelay = parseFloat(`0.${i*5}`);
                let delayLine = 1.5 + calcDelay;
                let delayLine2 = delayLine + 0.5 

                gsap.to(
                    line,
                    {
                        width: '100%',
                        duration: 0.5,
                        delay: delayLine,
                        scrollTrigger: {
                            trigger: element,
                            start: 'center bottom',
                            end: 'bottom bottom',
                        }
                    }
                );              
                gsap.to(
                    line,
                    {
                        x: '100%',
                        duration: 0.5,
                        delay: delayLine2,
                        scrollTrigger: {
                            trigger: element,
                            start: 'center bottom',
                            end: 'bottom bottom',
                        }
                    }
                );

                // let tl = gsap.timeline({
                //     repeat: -1, // Ripete la timeline all'infinito
                //     scrollTrigger: {
                //         trigger: element,
                //         start: 'center bottom',
                //         end: 'bottom bottom',
                //     }
                // });
                
                // // Aggiungere la prima animazione alla timeline
                // tl.to(line, {
                //     width: '100%',
                //     duration: 0.5,
                //     delay: delayLine
                // });
                
                // // Aggiungere la seconda animazione alla timeline
                // tl.to(line, {
                //     x: '100%',
                //     duration: 0.5,
                //     delay: delayLine2
                // }, "-=0.5");

                i++

            })
        });         
    }
}