import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();
import $ from 'jquery';


$( document ).ready(function() {
  
  const boxes = gsap.utils.toArray('.fadein');
  
  var tl = gsap.timeline();
  boxes.forEach((box, i) => {
    let delayBase = 0
    let calcDelay = parseFloat(`0.${i*3}`);
    let delay = delayBase + calcDelay

    let animation = gsap.fromTo(box, {
      opacity: 0,
      y: 50,
    }, 
    {
      delay: delay,
      ease: 'ease',
      opacity: 1,
      y: 0,
      duration: 0.6,
    });

    // Configura il scrollTrigger separatamente
    ScrollTrigger.create({
      trigger: box,
      start: 'center bottom', // Puoi regolare la posizione di inizio
      animation: animation, // Associa il trigger all'animazione
    });

  }); 
});