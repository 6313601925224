import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
    
    if (!document.body.classList.contains('home')) {
        setTimeout(() => {
            
            let imageContainer = document.querySelectorAll(".image-animation-container");
            
            imageContainer.forEach(element => {
                let imageWrapper = element.querySelector(".image-animation_wrapper");
                let img = element.querySelector(".image-animation");
                let buttonNext = element.querySelector(".image-button-next");
                
                if (buttonNext) {
                    gsap.to(
                        buttonNext, 
                        {
                            visibility: 'visible',
                            delay: 1.5,
                            duration: 0.3,
                            ease: 'power4',
                            scrollTrigger: {
                                trigger: element,
                                start: 'top 85%',
                            }
                        }
                    );
                }
    
                gsap.fromTo(
                    imageWrapper, 
                    {
                        scale: 0,
                        opacity: 1,
                    }, 
                    {
                        scale: 1,
                        duration: 2,
                        ease: 'power4',
                        scrollTrigger: {
                            trigger: element,
                            start: 'top 85%',
                        }
                    }
                );
            
                gsap.fromTo(
                    img, 
                    {
                        opacity: 1,
                        scale: 5,
                    }, 
                    {
                        scale: 1,
                        duration: 2,
                        ease: 'power4',
                        scrollTrigger: {
                            trigger: element,
                            start: 'top 85%',
                        }
                    }
                );
            });
        }, 0);
    }


});