import $ from 'jquery';
import Registry from "./Registry";

export default class Context {
  constructor(contextData) {
    this.contextData = contextData;
    this.loadComponents();
    this.defaultContext();
  }

  addEventListenerFrame(event, callback) {
    let timeout;
    if (typeof event == 'string') {
      event = [event];
    }
    event.forEach((ev) => {
      window.addEventListener(ev, (e) => {
        if (timeout) {
          window.cancelAnimationFrame(timeout);
        }
        timeout = window.requestAnimationFrame(() => callback(e));
      });
    });
  }

  initMouseCoords() {
    this.addEventListenerFrame('mousemove', (e) => {
      document.documentElement.style.setProperty('--mouse-client-x', `${e.clientX}px`);
      document.documentElement.style.setProperty('--mouse-client-y', `${e.clientY}px`);
    });
  }

  loadComponents(context) {
    let registry = new Registry(),
      selector = '[data-g-component]';


    $(selector, context).each((index, elem) => {
      if (!$(elem).data('g-component-initialized')) {
        let Cmp = registry.getComponent($(elem).data('g-component'));
        if (typeof Cmp === 'function') {
          new Cmp(elem, this.contextData);
          $(elem).data('g-component-initialized', true);
        }
      }
    });
  }

  getCssVar(name, elem = document.documentElement) {
    return getComputedStyle(elem).getPropertyValue(name);
  }

  getBreakpoint(bp, elem = document.documentElement) {
    let value = this.getCssVar(`--breakpoint-${bp}`, elem);
    if (value) {
      value = parseInt(value);
    }
    return value || 0;
  }

  defaultContext() {
    $(() => {
      this.initMouseCoords();
      $('.rte table').wrap('<div class="responsive-table" />');
    });
  }
}
