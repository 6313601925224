import Component from "../../models/Component";
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules';

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);

export default class SwiperSlider extends Component {
  constructor(element, context) {
    super(element, context);
    this.swiper = {};
  }

  init() {
    this.swiper = new Swiper(this.element[0], this.options);
  }

  setOptions(options) {
    this.options = options;
  }

  updateSize() {
    if (typeof this.swiper.update === "function") {
      this.swiper.update();
    }
  }

  destroy() {
    if (typeof this.swiper.destroy === "function") {
      this.swiper.destroy();
    }
  }

  getSwiper() {
    return this.swiper;
  }

  setParam(param, value) {
    this.swiper.params[param] = value;
  }
}
