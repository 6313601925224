import Component from "../models/Component";
import $ from 'jquery';
import Player from "@vimeo/player";

export default class Videovimeo extends Component {
  constructor(element, context) {
    super(element, context);
    $(() => {
      this.init();
    });
  }

  init() {
    this.playerContainer = this.element.find('[data-embed-target]');
    this.autoplay = this.getData('autoplay');
    this.playerContainer.find('img').hide();
    this.playButton = this.playerContainer.find('.play-button');
    this.overlay = this.element.find('.overlay-video');

    this.play = false;
    
    this.player = new Player(this.playerContainer.get(0), {
      id: this.getData('vimeo-video-id'),
      autoplay:  false,
      fullscreen: true,
      responsive: true,
      loop: false,
      dnt: 1,
      background: this.autoplay ? true : false,
    });

    this.playButton.on('click', () => {
      this.playVideo();
    });

    this.overlay.on('click', () => {
      this.CheckOverlay();
    });

    this.player.on('pause', () => {
      this.overlay.show();
      this.playButton.fadeIn();
    });
  }

  playVideo() {
    this.play = !this.play;
    this.player.play();
    this.playButton.fadeOut();
  }

  CheckOverlay() {
    this.play = !this.play;
    if(this.play) {
      this.player.pause();
    }
    else {
      this.player.play();
      this.playButton.fadeOut();
    }
  }
}
