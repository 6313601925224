import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
    if (document.body.classList.contains('single-progetti')) {


        let dettagliProgetti = document.querySelector(".dettagli-progetto");
        let lineaClienti = document.querySelector(".line");
        let nomeCliente = document.querySelector(".cliente");
        let chars = `AH@FIG[1]@____"""_"'_'''`;
        let contenuto = nomeCliente.textContent;

        gsap.fromTo(nomeCliente, 
        {
            opacity: 0,
        },
        {
            duration: 0.1, 
            opacity: 1,
            delay: 1,
            scrollTrigger: {
                trigger: nomeCliente,
                start: 'top bottom',
            },
        }) 

        gsap.to(nomeCliente, 
        {
            duration: 1,
            delay: 1,
            scrambleText:{
                text: contenuto,
                chars: chars,
                speed:2,
                revealDelay: 0.2,
            },
            scrollTrigger: {
                trigger: nomeCliente,
                start: 'top bottom',
            },
        }) 

        gsap.to(
            dettagliProgetti, {
                opacity: 1,
                duration: 1,
                delay: 0.5,
                ease: 'power4',
                scrollTrigger: {
                    trigger: dettagliProgetti,
                    start: 'top bottom',
                    delay: 0.6
                }
            }
        );

        gsap.to(
            lineaClienti, {
                width: '80px',
                duration: 3,
                ease: 'power4',
                scrollTrigger: {
                    trigger: lineaClienti,
                    start: 'top 80%',
                    delay: 0.6
                }
            }
        );

    }
});