import Registry from "./models/Registry";
import Slider from "./components/Slider";
import Accordion from "./components/Accordion"
import VimeoEmbed from "./components/VimeoEmbed";
import VideovimeoHp from "./components/VideovimeoHp";
import VideovimeoFull from "./components/VideovimeoFull";
import Modal from "./components/Modal";
import HeroHp from "./components/HeroHp";
import Videovimeo from "./components/Videovimeo";
import Servizi from './components/Servizi';
import './components/Fadein';
import './components/MainMenu';
import './components/HpSlider';
import './components/DomandeAccordion';
import './components/Management';
import "./components/FadeText";
import './components/LineCliente';
import "./components/FooterBg"
import './components/Cursor';
import './components/SplitText';
import './components/ImageAnimation';
import './components/Vanta';

class App {
  constructor(context) {
    this.registry = new Registry();
    this.setupUnloadScroll();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
    this.setvh();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
      .addComponent('Slider', Slider)
      .addComponent('Accordion', Accordion)
      .addComponent('VimeoEmbed', VimeoEmbed)
      .addComponent('Modal', Modal)
      .addComponent('HeroHp', HeroHp)
      .addComponent('Videovimeo', Videovimeo)
      .addComponent('VideovimeoHp', VideovimeoHp)
      .addComponent('VideovimeoFull', VideovimeoFull)
      .addComponent('Servizi', Servizi)
    ;
  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  setvh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
  }
  
  setupUnloadScroll() {
    setTimeout(function() {

      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 400);
  }
  
}

window.AppInit = function(context) {
    new App(context);
};
