import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);
export const func = {
    fadeInText: () => {
        document.addEventListener("DOMContentLoaded", function () {
            let time;
            let body = document.body;
            if (!body.classList.contains('post-type-archive-progetti')) {
    
                // Animazione bg testi
                let animationBgText = document.querySelectorAll('.bg-animation-actegorie')
    
                animationBgText.forEach(element => {
                    gsap.to(
                        element, {
                            width: '100%',
                            duration: 2,
                            ease: 'cubic-bezier(.17, .52, .47, 1)',
                            scrollTrigger: {
                                trigger: element,
                                start: 'top 85%',
                                delay: 0.6
                            }
                        }
                    );
                });
                
    
                // Special Heading
                let display1 = document.querySelectorAll('.text-display1');
                let display2 = document.querySelectorAll('.text-display2');
                let display3 = document.querySelectorAll('.text-display3');
                let display4 = document.querySelectorAll('.text-display4');
            
                // Heading
                let headingH1 = document.querySelectorAll('.text-heading-h1');
                let headingH2 = document.querySelectorAll('.text-heading-h2');
                let headingH3 = document.querySelectorAll('.text-heading-h3');
                let headingH4 = document.querySelectorAll('.text-heading-h4');
                let headingH5 = document.querySelectorAll('.text-heading-h5');
                let headingH6 = document.querySelectorAll('.text-heading-h6');
            
                // Body Copy
                // let bodyLarge = document.querySelectorAll('.text-body-large');
                // let bodyDefault = document.querySelectorAll('.text-body-default');
                // let bodySmall = document.querySelectorAll('.text-body-small');
                // let bodySmaller = document.querySelectorAll('.text-body-smaller');
            
            
            
                function processElements(element) {
            
                    const textContent = element.textContent;
                    const words = textContent.split(' ');
            
                    element.innerHTML = '';
            
                    words.forEach(word => {
                        const span = document.createElement('span');
                        span.textContent = word + ' '; 
                        element.appendChild(span);
                    });
                    
                }
            
                function fadeInSpans(element) {
                    
                    const spans = element.querySelectorAll('span');
                    let index = 0;
            
                    function fadeInSpan() {
                        if (index < spans.length) {
                            spans[index].style.opacity = 1;
                            index++;
                            setTimeout(fadeInSpan, 10* index);
                        }
                    }
                    let time = 0;
                    if (document.body.classList.contains('home')){
                        time = 300
                    }
                    setTimeout(() => {
                        fadeInSpan();
                    }, time);
                    
                }
            
                function createScrollTrigger(elements) {
                    elements.forEach(element => {
                        processElements(element);
            
                        ScrollTrigger.create({
                            trigger: element,
                            start: 'top 85%',
                            onEnter: () => {
                                fadeInSpans(element)
                            },
                            once: true,
                        });
                    });
                }
            
                createScrollTrigger(display1);
                createScrollTrigger(display2);
                createScrollTrigger(display3);
                createScrollTrigger(display4);
                createScrollTrigger(headingH1);
                createScrollTrigger(headingH2);
                createScrollTrigger(headingH3)
                createScrollTrigger(headingH4);
                createScrollTrigger(headingH5);
                createScrollTrigger(headingH6);
                // createScrollTrigger(bodyLarge);
                // createScrollTrigger(bodyDefault);
                // createScrollTrigger(bodySmall);
                // createScrollTrigger(bodySmaller);
                
            }
                
            

        });
    }
}

func.fadeInText();