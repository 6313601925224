import { gsap } from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

gsap.registerPlugin(ScrambleTextPlugin);

document.addEventListener("DOMContentLoaded", function () {
    
    
    if (!document.body.classList.contains('post-type-archive-progetti')){

        let frase = document.querySelectorAll(".frase p");
        let chars = `AH@FIG[1]@____"""_"'_'''`
        let delayMatrix = 0;
        if (document.body.classList.contains('home')){
            delayMatrix = 1.5
        }
        let i = 0
        frase.forEach(element => {
            let contenuto = element.textContent;
            let calcDelay = parseFloat(`0.${i*3}`);
            delayMatrix = calcDelay + delayMatrix;
    
            gsap.to(element, 
            {
                duration: 1,
                delay: delayMatrix,
                scrambleText:{
                    text: contenuto, 
                    chars: chars,
                    speed:2,
                    revealDelay: 0.1,
                }
            })
                
            gsap.to(element, 
            {
                duration: 0.2,
                delay: delayMatrix,
                opacity: 1,
            }) 

            i++
        });

    }




});