import $ from 'jquery';


document.addEventListener("DOMContentLoaded", function () {

  const $c = $("[data-custom-cursor]");
  let $permalink = null;

  setTimeout(function () {
    $permalink = $("a");
    $permalink.hover(
      () => $c.addClass("permalink"),
      () => $c.removeClass("permalink")
    );
  }, 850)

  $('.button-cta_wrapper').add('.play-button').add('.close-button').add('.checkbox-filter').add('.slide').hover(
    () => $c.addClass("permalink"),
    () => $c.removeClass("permalink")
  );

  $('.overlay-video').hover(
    () => {
        $c.addClass("permalink");
        $('.play-button').addClass("hoverplay");
    },
    () => {
        $c.removeClass("permalink");
        $('.play-button').removeClass("hoverplay");
    }
);

  $('.bg-conic').hover(
    () => $c.addClass("color-w"),
    () => $c.removeClass("color-w")
  )


  $(window).on("mousemove", ({
    clientX: x,
    clientY: y
  }) => {
    $c.css({
      opacity: `1`
    });
    $c.css({
      transform: `translate(${x}px, ${y}px)`
    });
  });


});