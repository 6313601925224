import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);


document.addEventListener("DOMContentLoaded", function () {

    if (window.innerWidth > 768) {

        
        if (document.body.classList.contains('post-type-archive-progetti') || document.body.classList.contains('post-type-archive-clienti') || document.body.classList.contains('post-type-archive-post') ) {
            
        } else {
            
            let footer = document.querySelector("footer");
            let footerContainer = document.querySelector(".footer-container");
            let footerContainerheight = footerContainer.offsetHeight;
            let partFooterContainerheight = footerContainerheight/3;
            let bodyFooter = document.body;
    
            gsap.to(
                footer,
                {
                    opacity: 1,
                    duration: 0.01,
                    scrollTrigger: {
                        trigger: bodyFooter,
                        start: () => "100%-=" + footerContainerheight + " bottom",
                    }
                }
            );  
    
            gsap.fromTo(
                footerContainer,
                {
                    opacity: 0,
                },
                {
                    opacity: 1,
                    scrollTrigger: {
                        trigger: bodyFooter,
                        scrub: true,
                        start: () => "100%-=" + footerContainerheight + " bottom",
                        end: () => "100%-=" + partFooterContainerheight + " bottom",
                    }
                }
            );  
        }

        


       
    }
    
});