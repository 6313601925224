import $ from 'jquery';
import {
  $Doc,
  $Win
} from "../utils/jQueryGlobals";
import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();

gsap.registerPlugin(ScrollTrigger);

var lastScrollTop = 0;
var scrollStopped = false;
var scrollTimer;

$(window).scroll(function () {
  var scrolltop = $(this).scrollTop();

  // if (window.innerWidth <= 768) {
  //   if (scrolltop < lastScrollTop) {
  //     // Sta scorrendo verso il basso
  //     clearTimeout(scrollTimer); // Cancella il timeout precedente
  //     scrollStopped = false; // Imposta scrollStopped a false
  //     if (scrollStopped) {
  //       $('header').removeClass('move-height');
  //     }
  //   } else {
  //     $('header').addClass('move-height');
  //   }
  //   lastScrollTop = scrolltop;

  //   scrollTimer = setTimeout(function() {
  //     scrollStopped = true;
  //     if (scrollStopped) {
  //       $('header').removeClass('move-height');
  //     }
  //   }, 1000); 
  // }
  // else {
    if(!$('body').hasClass('open-menu')){
      if (scrolltop < lastScrollTop) {

        $('header').removeClass('move-height');
      } else {
        $('header').addClass('move-height');
      }

      if(scrolltop == 0) {
        setTimeout(function(){
          $('header').removeClass('move-height')
        },250)
        
      }
    }

    lastScrollTop = scrolltop;
  // }
});



$Win.on('scroll', () => {
  if ($Doc.scrollTop() > 190) {
    $('header').addClass('no-top');
  } else {
    $('header').removeClass('no-top');
  }
});

let hamburgher = $('.hamburger');

$(hamburgher).on('click', function () {
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#menu-items, #contact').hide();
    $('html, body').removeClass('open-menu no-scroll');
  } else {
    $(this).addClass('is-active');
    $('#menu-items, #contact').show();
    $('html, body').addClass('open-menu no-scroll');

    let titoliHeader = document.querySelectorAll('.menu-item > a');


    titoliHeader.forEach(element => {
      gsap.from(element, {
        y: '110%',
        delay: 0.3,
        opacity: 0,
        stagger: { each: 0.1 },
      });
    });

  }
})

let nav_list = document.querySelectorAll('header #menu-items ul li');
let overlay = document.querySelector('.overlay');
let currentListItem = null;
let leaveTimeout;

nav_list.forEach((list) => {
  list.addEventListener('mouseover', (event) => {
    let position = list.getBoundingClientRect();
    overlay.classList.add('active');
    overlay.style.left = position.x + 'px';

    // Verifica se l'elemento su cui si sta facendo hover è diverso dall'elemento corrente
    if (list !== currentListItem) {
      currentListItem = list; // Aggiorna l'elemento corrente
    }

    // Cancella il timeout per l'evento 'mouseleave' (se esiste)
    clearTimeout(leaveTimeout);
  });

  list.addEventListener('mouseleave', () => {
    // Imposta un timeout per verificare se il mouse è rimasto fuori dagli elementi per un certo periodo di tempo
    leaveTimeout = setTimeout(() => {
      let checkcurrentitem = document.querySelector('header #menu-items ul li.current-menu-item');
      if(checkcurrentitem) {
        const position = checkcurrentitem.getBoundingClientRect();
        overlay.style.left = position.x + 'px';
        overlay.classList.add('active');
      }
      else {
        overlay.classList.remove('active');
      }

    }, 250); // Esempio di tempo di attesa (500 millisecondi)
  });

  if (list.classList.contains('current-menu-item')) {
    let position = list.getBoundingClientRect();
    overlay.style.left = position.x + 'px';
    overlay.classList.add('active');
  }

});